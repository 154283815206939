<template>
    <div>
      <div class="navigation_panel">
        <button class="btn btn-light" v-on:click="goVacation()">
          Відпустки
        </button>
        <button class="btn btn-light" v-on:click="goNotPayedOtgul()">
          Відгули за свій рахунок
        </button>
        <button class="btn btn-light" v-on:click="goOtgul()">
          Відгули за рахунок компанії
        </button>
      </div>
      <b-card v-if="currentSection === 1" title="Відпустки">
        <div class="large-12 medium-12 small-12 cell" >
            <b-table class="table table-sm table-striped table-bordered"
                id="my-table"
                :items="vacationsList"
                :fields="fields"
                :sort-by="sortBy"
                :per-page="perPage"
                :current-page="currentPage"
                small
            ></b-table>
            <b-pagination align="center"
                v-model="currentPage"
                :total-rows="vacationsList.length"
                :per-page="perPage"
                aria-controls="my-table"
            ></b-pagination>
          </div>
      </b-card>
      <b-card v-if="currentSection === 2" title="Відгули за рахунок компанії">
        <div class="large-12 medium-12 small-12 cell" >
            <b-table class="table table-sm table-striped table-bordered"
                id="my-table"
                :items="otguls"
                :fields="fields"
                :sort-by="sortBy"
                small
            ></b-table>
            <b-pagination align="center"
                v-model="currentPage"
                :total-rows="otguls.length"
                :per-page="perPage"
                aria-controls="my-table"
            ></b-pagination>
          </div>
      </b-card>
      <b-card v-if="currentSection === 3" title="Відгули за свій рахунок">
        <div class="large-12 medium-12 small-12 cell" >
            <b-table class="table table-sm table-striped table-bordered"
                id="my-table"
                :items="notPayedOtguls"
                :fields="fields"
                :sort-by="sortBy"
                :per-page="perPage"
                :current-page="currentPage"
                small
            ></b-table>
            <b-pagination align="center"
                v-model="currentPage"
                :total-rows="notPayedOtguls.length"
                :per-page="perPage"
                aria-controls="my-table"
            ></b-pagination>
          </div>
      </b-card>
    </div>
</template>
<script>
export default {
    data(){
        return {
          otguls:[],
          notPayedOtguls:[],
          vacationsList:[],
          currentSection: 1,
          perPage: 20,
          currentPage: 1,
          sortBy: 'name',
          fields: [
                  { key: 'name', sortable: true },
                  { key: 'email', sortable: false },
                  { key: 'startDate', sortable: false },
                  { key: 'endDate', sortable: false },
                  { key: 'daysTotal', sortable: false },
                  { key: 'daysUsed', sortable: false },
                  { key: 'daysLeft', sortable: false },
                ],
        }
    },
    mounted: async function(){
        await this.getOtguls();
        await this.getVacations();
    },
    methods: {
      goOtgul(){
        this.currentSection = 2;
      },
      async getOtguls(){
          let res = await fetch('https://vacation-inch-backend.azurewebsites.net/api/otgul/getotguls');
          let resJson = await res.json();
          this.otguls = resJson.otgul;
          this.notPayedOtguls = resJson.notPayedOtgul;
      },
      goNotPayedOtgul(){
        this.currentSection = 3;
      },
      async getVacations(){
          let jwtToken = this.$store.getters.getJwtToken;
          console.log(jwtToken);
          let res = await fetch('https://vacation-inch-backend.azurewebsites.net/api/vacation',{
              method:'GET',
              headers:{
                  'Authorization':'Bearer '+ jwtToken
              }
          });
          let resJson = await res.json();
          console.log(resJson);
          this.vacationsList = resJson;
      },
      goVacation(){
        this.currentSection = 1;
      },
      changeFields(item){
        item.daysLeft = item.DaysLeft;
        item.daysTotal = item.DaysTotal;
        item.email = item.Email;
        item.endDate = item.EndDate;
        item.name = item.Name;
        item.startDate = item.StartDate;
        return item;
      },
      logout: function () {
        this.$store.dispatch('logout');
      }
    },
}
</script>
<style scoped lang="scss">
.navigation_panel{
  display:flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
</style>